import React from "react";
import octicons from "octicons";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import ExternalBlogs from "../external-blogs.json";

const MediumLabel = () => (
  <span
    className="Label bg-blue-light text-gray"
    title="External article on Medium"
  >
    Medium
    <span
      className="pl-1"
      dangerouslySetInnerHTML={{
        __html: octicons["link-external"].toSVG({
          style: "fill: currentColor",
          width: "12",
        }),
      }}
    />
  </span>
);

const IndexPage = () => (
  <div className="d-flex flex-column">
    <header className="flex-row flex-lg-column mt-4 mt-lg-6 mx-auto px-3 container-md width-full">
      <a
        href="https://www.rmhartog.me/"
        className="f1 tooltipped tooltipped-s text-mono muted-link"
        aria-label="rmhartog.me homepage"
      >
        rmhartog
        <span className="f1" style={{ opacity: 0.75 }}>
          .me
        </span>
      </a>
    </header>
    <header className="d-flex flex-column flex-sm-row mt-4 mt-lg-6 mx-auto px-3 container-md width-full">
      <img
        src="https://www.github.com/rmhartog.png?s=144"
        className="bg-blue-light avatar flex-self-center flex-shrink-0 mb-2 mb-sm-0"
        width="72"
        height="72"
      />
      <div className="px-3 flex-self-center">
        <p className="f2-light mb-0 text-center text-sm-left">
          Reinier Hartog's personal blog
        </p>
        <p className="f3-light mb-2 text-center text-sm-left">
          Thoughts on tech, opinions are my own.
        </p>
      </div>
    </header>
    <main className="mb-auto flex-row flex-lg-column mt-6 mt-lg-8 mx-auto px-3 width-full container-md">
      <h1 className="f1 mb-3 width-full">Recent articles</h1>
      {ExternalBlogs.articles.map(article => (
        <div
          className="d-flex flex-column flex-md-row mb-3"
          key={article.external_link}
        >
          <p
            className="text-mono mb-3 mr-3 pt-1"
            style={{ minWidth: "25%", flexShrink: 0 }}
          >
            {article.published_at}
          </p>
          <div className="d-flex flex-column">
            <h2 className="f3">
              <OutboundLink href={article.external_link} className="muted-link">
                {article.title} <MediumLabel />
              </OutboundLink>
            </h2>
            {article.intro && <p className="mt-3">{article.intro}</p>}
          </div>
        </div>
      ))}
    </main>
    <footer className="d-flex flex-row flex-justify-center mt-lg-6 my-4 mx-auto px-3 container-md width-full">
      <span
        className="d-flex"
        style={{ flexBasis: 0, flexShrink: 0, flexGrow: 1 }}
      >
        &copy; Reinier Hartog, {new Date().getFullYear()}.
      </span>
      <span
        className="d-flex flex-justify-end"
        style={{ flexBasis: 0, flexShrink: 0, flexGrow: 1 }}
      >
        <OutboundLink
          href="https://twitter.com/rm_hartog"
          className="btn-link text-mono"
        >
          Twitter
        </OutboundLink>
        &nbsp;&mdash;&nbsp;
        <OutboundLink
          href="https://github.com/rmhartog"
          className="btn-link text-mono"
        >
          GitHub
        </OutboundLink>
      </span>
      <span
        className="d-flex flex-justify-end d-none"
        style={{ flexBasis: 0, flexShrink: 0, flexGrow: 1 }}
      />
    </footer>
  </div>
);

export default IndexPage;
